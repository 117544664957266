<template>
  <div class="contentPendingChatFilter">
    <!-- <span class="text-primary title">
      {{ lang.attentionRequests.sidenav.otherChats[languageSelected] }}
      <span v-show="pendingCount">&nbsp;({{ pendingCount }})</span>
    </span> -->
    <div class="category mb-4 mr-4" @click.stop>
      <multiselect
        id="filterPendingChat"
        v-model="selectedCategoryR"
        :placeholder="
          lang.attentionRequests.sidenav.categoryFilter.placeholder[
            languageSelected
          ]
        "
        label="title"
        track-by="title"
        :options="categoryOptions"
        :option-height="46"
        :show-labels="false"
        :allow-empty="false"
        :searchable="false"
        open-direction="bottom"
        @input="onCategorySelect"
      >
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title" v-if="props.option.title">
              {{ props.option.title }}
              <span>({{ props.option.count }})</span>
            </span>
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <section class="option-wrapper">
            <feather-icon
              v-if="props.option.iconPack === 'feather'"
              :icon="props.option.icon"
            />
            <i
              v-if="props.option.iconPack === 'material'"
              class="material-icons"
              >{{ props.option.icon }}</i
            >
            <div class="option__desc ml-2">
              <span class="option__title">{{ props.option.title }}</span>
            </div>
          </section>
          <vs-chip>{{ props.option.count }}</vs-chip>
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PendingChatFilter',
  data() {
    return {
      selectedCategoryR: null
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  props: {
    categoryOptions: {
      type: Array,
      required: true
    },
    pendingCount: Number,
    selectedCategory: Object
  },
  methods: {
    onCategorySelect() {
      // this.$emit('update:selectedCategory', this.selectedCategoryR)
      this.$emit('onCategorySelect', this.selectedCategoryR)
    }
  },
  mounted() {
    this.selectedCategoryR = this.selectedCategory
  },
  computed: {
    ...mapState([
      'activeChatUser',
      'bot',
      'lang',
      'languageSelected',
      'config',
      'session',
      'joinUpdate',
      'newChatUserName',
      'chatGeneralSettings',
      'takeoverPending'
    ])
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
#filterPendingChat {
  .vs-collapse-item--content {
    overflow: visible;
  }
}
.contentPendingChatFilter {
  display: flex;
  flex-direction: column;
  .title {
    // margin-top: auto;
    // margin-bottom: auto;
    // line-height: 0.8em;
    display: flex;
    // justify-content: space-between;
    font-size: 1.1rem;
    padding: 0 20px 5px 15px;
  }
  .category {
    .multiselect__tags {
      width: unset;
    }
  }
}
</style>
